*::-webkit-scrollbar {
  display: none;
}

* {
  font-family: 'Baskervville', serif;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Lora', serif;
  font-family: 'Varela', sans-serif;
  font-family: 'Varela Round', sans-serif;
  font-family: 'Quicksand', sans-serif;
}

body {
  background: linear-gradient(0deg, rgba(10, 10, 10, 0.20) 0%, rgba(10, 10, 10, 0.20) 100%), linear-gradient(155deg, #0D375C 0%, #14151F 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: Quicksand;
}
.min-height{
  min-height: 15vh;
}

.loading-background {
  background: linear-gradient(
      0deg,
      rgba(10, 10, 10, 0.2) 0%,
      rgba(10, 10, 10, 0.2) 100%
    ),
    linear-gradient(155deg, #0d375c 0%, #14151f 100%);
  transition: background 300ms ease-in-out;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Quicksand;
  text-transform: capitalize;
}

select {
  background-color: #161616 !important;
}

p,
span {
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 0px !important;
  /* text-transform: capitalize; */

}
.sidebar{
  overflow: hidden;
  transition: all 0.3s ease;
}

.nav-text {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
}

.cus-nav {
  padding: 15px 20px;
}

.br-15 {
  border-radius: 15px;
}

.cus-dark {
  background-color: #1A1A1A;
}

.cus-text {
  font-size: 10px;
  font-family: 'Varela Round', sans-serif;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms; /* Match this to timeout in CSSTransition */
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.page-enter {
  opacity: 0;
  transform: translateY(100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms, transform 300ms;
}


input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"] {
  background-color: #1A1A1A !important;
  color: rgba(255, 255, 255, 0.40) !important;
  font-family: 'Josefin Sans', sans-serif;
  padding: 10px;
  border: 5px solid #1A1A1A !important;
  padding-left: 50px;
  align-items: center;
  border: none;
}

input[type="email"],
input[type="text"],
input[type="password"] {

  padding-left: 20px;
  border: 1px solid #fff !important;
  border-radius: 15px;

}
input::placeholder {
  color: rgba(255, 255, 255, 0.40) !important; /* Change the color to your desired color */
}
.bg-black{
  background-color: #000000;
  opacity: 0.75;
  margin-bottom: 10px;
}
.mt-40{
  margin-top: 40px !important;
}
.ml-40{
  margin-left: 24px !important;
}

.mr-4{
  margin-right: 4px !important;
}
.mb-40{
  margin-bottom: 40px !important;
}
.mr-20{
  margin-right: 20px !important;
}

.cus-img {
  background-image: linear-gradient(lightgray 100%, #FFF 50%);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  background-color: transparent;
  height: 50px !important;
  border-radius: 15px;
  object-fit: cover;
  /* width: 50px; */
}
.latest-img{
  border-radius: 15px;
  height: 5rem;
  width: 5rem;
  object-fit: cover;
}

.cus-icon {
  position: absolute;
  margin-left: 20px;
  justify-content: start !important;
}


.cus-mar {
  margin-left: auto !important;
}

.img-text {
  position: absolute;
  top: 300px;
  left: 600px;
}

.image-container h2 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 900;
  font-size: 50px;
}

.image-container p {
  color: rgba(255, 255, 255, 0.80);
}

.btn {
  font-family: 'Varela Round', sans-serif;

}

.pl {
  padding-left: 15px;
}

/* .img-logo {
  height: 90px;
  width: 215px;
} */

/* Add your custom styles here */
.img-text {
  padding: 1rem;
}

.img-logo {
  max-height: 215px;
}

.image-container .image-container-play {
  overflow: hidden;
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate(-50%, -50%);
}

.text-overlay h2 {
  font-size: 24px;
}

.text-overlay svg {
  fill: url(#paint0_linear_1_283);
}

.start-50-cus {
  left: 38% !important;
}

.start-50-cust {
  left: 32% !important;
}

.top-50-cus {
  top: 36% !important;
}

.top-50-cust {
  top: 34% !important;
}

.bottom-0-cus {
  bottom: -30% !important;
}

.music-title {
  color: #FFF;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 500;
  /* 100% */
}

.text-blue {
  color: #5C67DE;
  font-family: Quicksand;
  font-size: 16px;

}

.pause-icon {
  height: 30px;
  width: 30px;
}

.address {
  height: 47px;
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  line-height: 16px !important;
  /* 100% */
}

.address-out {
  padding: 0px 0px 20px 40px;
  gap: 10px;
}

.blank-div {


  padding: 15px 20px;
}

/* .start-50-play {
  left: 16%!important;
}
.top-50-play {
  top: 70%!important;
} */
.cus-img-play {
  background-image: linear-gradient(lightgray 50%, #FFF 50%);
}

.cus-text-play {
  color: rgba(255, 255, 255, 0.60);
  font-family: Quicksand;

}

.img-logo-play {
  width: 175px;
  max-height: 150px;
}

.cus-text-p {
  font-size: 12px;
}

.bg-blue {
  background-color: #5C67DE;
}

.play-text {
  color: rgba(255, 255, 255, 0.30);
}

.br-color {
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}

/* Custom styling for the progress bar */
.custom-progress {
  height: 5px !important;
  width: auto;
  background-color: rgba(255, 255, 255, 0.30) !important;
  border-radius: 5px;
}

.custom-progress-bar {
  background-color: white !important;
  border-radius: 5px;
}

.square {
  padding-bottom: 100%;
}

.text-footer {
  line-height: 20px;
  font-size: 14px;
}

.text-custom {
  font-size: 16px;
}

.baskervville {
  font-family: 'Baskervville', serif;
}

.lora {
  font-family: 'Lora', serif;
}

.varela {
  font-family: 'Varela', sans-serif;
}

.varela-round {
  font-family: 'Varela Round', sans-serif;
}

.josefin-sans {
  font-family: 'Helvetica', sans-serif;
}

.quicksand {
  font-family: 'Helvetica', sans-serif;
}

/* Custom styles for the search box in data Table*/
.dataTables_filter {
  /* background-color: #f2f2f2; */

  padding: 8px;
  border-radius: 5px;
}

/* Styling the search input field */
.dataTables_filter input[type="search"] {
  border: 1px solid #ccc !important;
  border-radius: 5px;
}

/* Modal styles */
.modal-dialog {
  margin: 0;
  max-width: 100%;
  width: max-content;
}

.modal-content {
  background: transparent !important;
  border: none;
}

/* .modal {
  top: -100px !important;

} */

.modal-body {
  padding: 0;
  text-align: center;

}

.modal-close {
  position: relative;
  top: 0px;
  right: 0px;
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  z-index: 9999;
}



.document-viewer {
  max-height: calc(100vh - 120px);
  /* Adjust as needed to fit within the viewport */
  max-width: calc(100vw - 40px);
  /* Adjust as needed to fit within the viewport */
  margin: 0 auto;
}





/* autocomplete dropdown style */
.ui-autocomplete {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 5px;
  z-index: 9999;
  border-radius: 15px;

}

.ui-autocomplete .ui-menu-item {
  list-style-type: none;
  padding: 5px;
  cursor: pointer;
}

.ui-autocomplete .ui-menu-item:hover {
  background-color: #f0f0f0;
}

.ui-autocomplete .ui-state-active {
  background-color: #cccccc;
}

.fs-12{
  font-size: 12px !important;
}
.fs-10{
  font-size: 10px !important;
}
.fs-14{
  font-size: 14px !important;
}
.fs-16{
  font-size: 16px !important;
}
.hover-effect:hover {
  /* Add your hover effect styles here */
  /* For example, you can change the color on hover */
  opacity: 0.6; /* This will increase brightness on hover */
}
/* Add this to your CSS to handle the sidebar animation and mobile view */

.bg-player{
  background: linear-gradient(0deg, rgba(10, 10, 10, 0.20) 0%, rgba(10, 10, 10, 0.20) 100%), linear-gradient(180deg, #0D375C 0%, #14151F 100%);
}

.gradient-background {
  /* background: rgb(0,0,0); */
  background: linear-gradient(360deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.cus-size{
  height: 32px;
  width: 32px;
}
.playicon{
 
  margin-left: 3px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.container-transition-enter {
  opacity: 0;
  transform: translateY(20px);
}

.container-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.container-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.container-transition-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}




.is-active{
  visibility: visible;
}

.nav-link {
  transition: opacity 0.3s ease;
}

.nav-link:hover {
  opacity: 0.8;
}
.song-details,
.artist-details,
.count-details,
.duration-details,
.share-details {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.play-text:hover .song-details,
.play-text:hover .artist-details,
.play-text:hover .count-details,
.play-text:hover .duration-details,
.play-text:hover .share-details {
  opacity: 0.8;
}

/* Add this CSS to your existing styles or a separate stylesheet */
.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px; /* Adjust the top and right values to position the button */
  right: 10px;
  color: #fff; /* Color of the close button icon */
}